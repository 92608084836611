import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import Button from '../../core/components/Button'
import { useQueryParams } from '../../core/hooks/queryString'
import { useEmployeesSimple } from '../../staff/hooks/employee'
import { EmployeeType } from '../../staff/types'
import { useNavigate } from 'react-router-dom'
import { stringify } from 'query-string'


export default function CustomerDebtorsFilter() {
    const navigate = useNavigate()
    const params = useQueryParams()
    const teachers = useEmployeesSimple({ position: 'teacher' })
    const teacherByParams = params?.teacher && teachers.data?.results.length ?
        teachers.data?.results.find(teacher => teacher.id.toString() === params?.teacher)?.name
        : 'Фильтровать по преподавателям'

    function loadTeachers() {
        if (teachers.data) return
        teachers.refetch()
    }

    function filter(teacher?: EmployeeType) {
        navigate(`?${stringify({ ...params, teacher: teacher?.id })}`)
    }

    return (
        <Dropdown trigger={
            <Button
                text={teacherByParams}
                loading={teachers.isLoading}
                onClick={loadTeachers}
                icon="ion-md-cash"
                className="is-small is-outlined"/>
        }>

            <DropdownItem
                onClick={() => filter()}
                text="Все Должники"/>

            {teachers.data?.results.map((teacher) => (
                <DropdownItem
                    key={teacher.id}
                    onClick={() => {
                        filter(teacher)
                    }}
                    active={params?.teacher === String(teacher.id)}
                    text={teacher.name}
                />
            ))}
        </Dropdown>
    )
}
