import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import Button from '../../core/components/Button'
import { useQueryParams } from '../../core/hooks/queryString'
import { useEmployeesSimple } from '../../staff/hooks/employee'
import { useState } from 'react'
import { EmployeeType } from '../../staff/types'
import { useNavigate } from 'react-router-dom'
import { stringify } from 'query-string'

export type Props = {
    setSellerWithDefault: (seller: number | null) => void
}

export default function CustomerActionsSellers({ setSellerWithDefault }: Props) {
    const navigate = useNavigate()
    const params = useQueryParams()
    const sellers = useEmployeesSimple({ position: 'seller' })
    const [sellerName, setSellerName] = useState<string>('')

    function loadSellers() {
        if (sellers.data) return
        sellers.refetch()
    }

    function filter(seller?: EmployeeType) {
        navigate(`?${stringify({ ...params, seller: seller?.id })}`)
    }

    return (
        <Dropdown trigger={
            <Button
                text={`Менеджер ${sellerName}`}
                loading={sellers.isLoading}
                onClick={loadSellers}
                icon="ion-md-person"
                className="is-small is-outlined"/>
        }>

            <DropdownItem
                onClick={() => filter()}
                text={(
                    <div onClick={() => {
                        setSellerName('')
                        setSellerWithDefault(null)
                    }}>
                        Все Менеджеры
                    </div>
                )}/>

            {sellers.data?.results.map((seller) => (
                <DropdownItem
                    key={seller.id}
                    onClick={() => {
                        filter(seller)
                        setSellerName(seller.name)
                    }}
                    active={params?.seller === String(seller.id)}
                    text={seller.name}
                />
            ))}
        </Dropdown>
    )
}
