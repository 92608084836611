import { cn } from '../../core/utils/classname'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { format } from '../../core/utils/number'
import phoneFormat from '../../core/utils/phones'
import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import { checkPermission as cp } from '../../users/utils/auth'
import { useModal } from '../../core/hooks/modal'
import StudentPayment from './StudentPayment'
import StudentInfo from './StudentInfo'
import { expirationClass, formatDate } from '../../core/utils/date'
import DebtorDelays from './DebtorDelays'
import DebtorDiscount from './DebtorDiscount'
import DebtorUpdate from './DebtorUpdate'
import DebtorDeactivation from './DebtorDeactivation'
import { DebtorType } from '../types'

export type Props = {
    item: DebtorType
    onPaymentCreate: () => void
    onDelayCreated: (expiresAt: string) => void
    onUpdate: (data: DebtorType) => void
    onDeactivation: () => void
}

export default function DebtorItem({ item, onPaymentCreate, onDelayCreated, onUpdate, onDeactivation }: Props) {
    const { customer, group } = item.student
    const isNew = !item.deactivationAttempt && (!item.seenAt || moment() <= moment(item.seenAt).add(8, 'hour'))

    const [showPaymentModal, hidePaymentModal] = useModal(
        <StudentPayment
            student={item.student}
            onSuccess={() => {
                hidePaymentModal()
                onPaymentCreate()
            }}/>,
    )

    const [showDiscountModal, hideDiscountModal] = useModal(
        <DebtorDiscount
            debtor={item}
            onSuccess={(data) => {
                hideDiscountModal()
                onUpdate(data)
            }}/>,
    )

    const [showDeactivationModal, hideDeactivationModal] = useModal(
        <DebtorDeactivation
            debtor={item}
            onSuccess={(data) => {
                hideDeactivationModal()
                onUpdate(data)
            }}
            onDeactivation={() => {
                hideDeactivationModal()
                onDeactivation()
            }}/>,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(
        <DebtorUpdate
            debtor={item}
            onSuccess={(data) => {
                hideUpdateModal()
                onUpdate(data)
            }}/>,
    )

    const [showDelaysModal, hideDelaysModal] = useModal(
        <DebtorDelays
            debtor={item}
            onSuccess={(expiresAt) => {
                hideDelaysModal()
                onDelayCreated(expiresAt)
            }}/>,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <StudentInfo onCancel={() => hideInfoModal()} student={item.student}/>,
    )

    return (
        <tr>
            <td data-label="Срок" onClick={showDelaysModal}
                className="is-narrow pointer">
                {item.deactivatedAt && item.payed ? (
                    <span className="tag is-primary is-rounded">Оплатил</span>
                ) : item.deactivatedAt ? (
                    <span className="tag is-danger is-rounded">Деактивирован</span>
                ) : (
                    <span className={cn('tag is-light is-rounded', expirationClass(item.expiresAt))}>
                        {formatDate(item.expiresAt)}
                    </span>
                )}
            </td>


            <td data-label="Имя" onClick={showInfoModal}
                className={cn('pointer', { 'has-text-weight-bold': isNew, 'has-text-grey': item.deactivatedAt })}>
                {customer?.name}
            </td>

            <td data-label="Телефон" className="pointer">
                <a href={`tel:${customer?.phone}`}
                    className={cn({ 'has-text-grey': item.deactivatedAt })}>
                    {phoneFormat(customer?.phone)}
                </a>
                <br/>
                <a href={`tel:${customer?.phoneSecond}`}
                    className={cn({ 'has-text-grey': item.deactivatedAt })}>{phoneFormat(customer?.phoneSecond)}
                </a>
            </td>

            <td data-label="Группа" className="pointer">
                <NavLink to={`/academy/group/${group.id}/students-list`}
                    className={cn({ 'has-text-grey': item.deactivatedAt })}>
                    {group.name} ({item.teacher.name})
                </NavLink>
            </td>

            <td data-label="Долг">
                <span className={cn('tooltip', { 'has-text-grey': item.deactivatedAt })}>
                    {format(item.amount || 0)} сум
                    <span className="tooltip-text">
                        C: {moment(item.startDate).format('DD.MM.YYYY')}<br/>
                        По: {moment(item.endDate).format('DD.MM.YYYY')}
                    </span>
                </span>

                {item.discount ? (
                    <span className={cn('tooltip', { 'has-text-grey': item.deactivatedAt })}>
                        <span className={cn('tag is-warning is-light ml-2', { 'has-text-grey': item.deactivatedAt })}>
                            -{format(item.discount || 0)} сум
                        </span>

                        <span className="tooltip-text">
                            Скидка: {item.discountReason}
                        </span>
                    </span>
                ) : null}
            </td>

            <td data-label="Действия">
                <Dropdown trigger={<i className="icon ion-md-more"/>} right>
                    <DropdownItem onClick={showPaymentModal} icon="ion-md-cash" text="Оплата"/>
                    <DropdownItem onClick={showDelaysModal} icon="ion-md-alarm" text="Отсрочить"/>

                    {cp('academy.discount_debtor') ? (
                        <DropdownItem
                            onClick={showDiscountModal}
                            icon="ion-md-pricetag"
                            text="Скидка"/>
                    ) : null}

                    {cp('academy.change_debtor') ? (
                        <DropdownItem
                            onClick={showUpdateModal}
                            icon="ion-md-create"
                            text="Изминить"/>
                    ) : null}

                    {cp('academy.delete_debtor') || cp('academy.deactivation_request_debtor') ? (
                        <DropdownItem
                            onClick={showDeactivationModal}
                            icon="ion-md-checkmark"
                            text="Деактивировать долг"/>
                    ) : null}
                </Dropdown>

                <span className="tooltip">
                    {item.deactivationAttempt ? (
                        <i className="icon ion-md-close-circle has-text-danger"/>
                    ) : null}
                    <span className="tooltip-text">Запрос на удаление</span>
                </span>
            </td>
        </tr>
    )
}
