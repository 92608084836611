import { css, StyleSheet } from 'aphrodite'
import { NavLink, useLocation } from 'react-router-dom'
import { ReactNode, useState } from 'react'
import { checkPermission as cp } from '../../users/utils/auth'
import { getEnv } from '../utils/environment'
import AccountPanel from './AccountPanel'
import logo from '../static/logo.png'
import { cn } from '../utils/classname'
import { useGeneralInfo } from '../hooks/general'
import { useIsOnline, useIsUpdating } from '../hooks/request'
import Loader from './Loader'

export type Props = {
    children?: ReactNode
    padding?: boolean
    className?: string
}

export default function Layout({ children, padding = true, className }: Props) {
    const online = useIsOnline()
    const isUpdating = useIsUpdating()
    const { pathname } = useLocation()
    const [menu, setMenu] = useState(false)
    const general = useGeneralInfo()
    const user = JSON.parse(localStorage.getItem('user') || '{}')

    return (
        <div>
            <div className={css(
                styles.sidebar,
                menu ? styles.desktopSidebar : styles.mobileSidebar,
                getEnv() === 'development' ? styles.developmentSidebar : null,
                getEnv() === 'staging' ? styles.stagingSidebar : null,
            )}>
                <div className="has-text-right pt-4">
                    <i onClick={() => setMenu(false)} className={cn(
                        'icon ion-md-arrow-back is-size-3',
                        css(styles.hiderIcon),
                    )} />
                </div>

                <div className={css(styles.loader)}>
                    {isUpdating ? (
                        <span className="tag is-link">
                            <Loader inline className="mr-2" text="Обновляется..." />
                        </span>
                    ) : null}

                    {!online ? (
                        <span className="tag is-warning is-light">
                            <i className="ion-md-warning mr-2"/>
                            Нет подключения!
                        </span>
                    ) : null}
                </div>

                <img src={logo} className={css(styles.logo)} alt="CRM Neo" />
                <AccountPanel />

                <p className={css(styles.menuHeading)}>
                    Меню
                </p>

                <ul className={css(styles.menu)}>
                    {cp('academy.view_group') || cp('academy.view_advert') || cp('academy.view_customers') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/academy/customers" className={cn(css(styles.link),
                                { [css(styles.active)]: pathname.startsWith('/academy') })}>
                                <i className={cn('icon ion-md-school', css(styles.icon))} />
                                Академия
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('finance.view_wallet') || cp('finance.view_transactioncategory') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink className={css(styles.link,
                                pathname.startsWith('/finance') ? styles.active : null)} to="/finance/wallet">
                                <i className={cn('icon ion-md-wallet', css(styles.icon))} />
                                Финансы
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('staff.view_employee') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/staff/employee" className={css(
                                styles.link,
                                pathname.startsWith('/staff/employee') ? styles.active : null,
                            )}>
                                <i className={cn('icon ion-md-contacts', css(styles.icon))} />
                                Персонал
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('task.view_task') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to={`/task/?assigned=${user.id}&status=waiting`} className={css(
                                styles.link,
                                pathname.startsWith('/task') ? styles.active : null,
                            )}>
                                <i className={cn('icon ion-md-checkbox', css(styles.icon))} />
                                Задачи &nbsp;

                                <span className="tag is-success is-rounded">
                                    {general?.data?.tasksCount || 0}
                                </span>
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('academy.view_advert_statistics') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/statistics/academy" className={css(
                                styles.link,
                                pathname.startsWith('/statistics') ? (
                                    styles.active
                                ) : null,
                            )}>
                                <i className={cn('icon ion-md-stats', css(styles.icon))} />
                                Статистика
                            </NavLink>
                        </li>
                    ) : null}

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/settings/academy" className={cn(css(styles.link),
                            { [css(styles.active)]: pathname.startsWith('/settings') })}>
                            <i className={cn('icon ion-md-settings', css(styles.icon))} />
                            Настройки
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className={cn(css(styles.container))}>
                <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                    <div>
                        <i onClick={() => setMenu(true)} className={cn(
                            'icon ion-md-menu is-size-3',
                            css(styles.showerIcon),
                        )} />
                    </div>
                    {children}
                </div>
            </div>

            <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
        </div>
    )
}

const styles = StyleSheet.create({
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        background: '#1f2668',
        width: '14.1rem',
        margin: '0',
        color: 'white',
        padding: '0 1.2rem 0 2.8rem',
        zIndex: 5,
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 525px)': {
            height: 'calc(100vh - 100%)',
            overflowX: 'scroll',
        },
    },
    developmentSidebar: {
        background: '#004d40',
    },
    stagingSidebar: {
        background: '#000000',
    },
    desktopSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(0)',
            transition: 'all 300ms',
        },
    },
    mobileSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(-14rem)',
            transition: 'all 300ms',
        },
    },
    icon: {
        margin: '0 0.3rem 0.9rem 0',
    },
    link: {
        color: 'RGBA(255, 255, 255, .6)',
    },
    loader: {
        position: 'absolute',
        height: '1rem',
        opacity: .8,
    },
    logo: {
        margin: '3.4rem 0.4rem',
        width: '8rem',
    },
    menuHeading: {
        color: 'RGBA(255,255,255, .6)',
        margin: '0 0 1.5rem 0',
    },
    menu: {
        margin: '0 0 3.1rem 0',
    },
    padding: {
        padding: '1.8rem',
        // paddingBottom: '12rem',
    },
    container: {
        margin: '0 0 0 14rem',
        '@media (max-width: 769px)': {
            margin: '0',
        },
    },
    active: {
        fontWeight: 'bolder',
        color: 'white',
    },
    content: {
        minHeight: '100vh',
        // paddingBottom: '12rem',
    },
    showerIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    hiderIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    background: {
        '@media (max-width: 769px)': {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            background: 'RGBA(0, 0, 0, .5)',
            zIndex: 4,
        },
    },
})
