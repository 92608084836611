import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { StudentType } from '../types'
import { PAYMENT_STUDENT, STUDENT_DETAIL, STUDENT_LIST, STUDENT_STOP } from '../urls'
import { StudentTransactionType } from '../../finance/types'

export type StudentsParams = {
    group?: number | string
    state?: number | string
    page?: number | string
}

export function useStudents({ group, state, page }: StudentsParams) {
    return useLoad<Pagination<StudentType>>({
        url: STUDENT_LIST,
        params: { group, state, page },
    }, [group, state, page])
}

export function useStudentCreate() {
    return usePostRequest<StudentType>({ url: STUDENT_LIST })
}

export function useStudentUpdate(id: number) {
    return usePutRequest<StudentType>({ url: STUDENT_DETAIL.replace('{id}', String(id)) })
}

export function useStudentDelete(id: number) {
    return useDeleteRequest({ url: STUDENT_DETAIL.replace('{id}', String(id)) })
}

export type StudentPaymentsParams = {
    student?: number
    customer?: number
    page?: number | string
}

export function useStudentPayments({ student, customer, page }: StudentPaymentsParams) {
    return useLoad<Pagination<StudentTransactionType>>({
        url: PAYMENT_STUDENT,
        params: { student, customer, page },
    }, [page, customer, student])
}

export function useStudentPaymentsCreate() {
    return usePostRequest({ url: PAYMENT_STUDENT })
}

export function useStudentStop(id: number) {
    return usePutRequest({ url: STUDENT_STOP.replace('{id}', String(id)) })
}

