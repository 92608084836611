import { Fragment } from 'react'
import Layout from '../../core/components/Layout'
import Table from '../../core/components/Table'
import StudentItem from '../components/StudentItem'
import Loader from '../../core/components/Loader'
import { useParams, useQueryParams } from '../../core/hooks/queryString'
import GroupTabs from '../components/GroupTabs'
import { useStudents } from '../hooks/student'
import { useGroup } from '../hooks/group'

export default function StudentsList() {
    const { groupId } = useParams()
    const params = useQueryParams()
    const group = useGroup(groupId!)
    const students = useStudents({ group: groupId, state: params.state as string, page: params.page as string })

    if (students.isLoading || group.isLoading || !group.data) {
        return <Layout><Loader large center /></Layout>
    }

    return (
        <Layout>
            <Fragment>
                <GroupTabs group={group.data} />

                <Table
                    responsive
                    pageSize={100}
                    columns={{
                        id: '№',
                        name: 'Имя',
                        phone: 'Телефон',
                        totalPaid: 'Оплачено',
                        monthPaid: 'Опл. в этом месяце',
                        price: 'Цена курса',
                        actions: '',
                    }}
                    items={students.data?.results}
                    totalCount={students.data?.count || 0}
                    renderItem={(item, index) => (
                        <StudentItem
                            key={item.id}
                            onSuccess={students.refetch}
                            onPaymentCreate={students.refetch}
                            item={item}
                            index={index}/>
                    )} />
            </Fragment>
        </Layout>
    )
}
