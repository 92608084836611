import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../../core/hooks/request.legacy'
import { Pagination } from '../../core/types'
import { DebtorDelayType, DebtorType, GeneralMonthType } from '../types'
import { DEBTOR_DELAYS_LIST,
    DEBTORS_DEACTIVATION,
    DEBTORS_DETAIL,
    DEBTORS_DISCOUNT,
    DEBTORS_GENERAL,
    DEBTORS_LIST } from '../urls'
import { useFetch, useInfiniteFetch } from '../../core/hooks/request'
import { request } from '../../core/utils/request'

export type DebtorsParams = {
    month: string
    teacher?: string
}

export function useDebtors({ month, teacher }: DebtorsParams) {
    return useInfiniteFetch<DebtorType>(['debtors', { month, teacher }], ({ pageParam }) => request({
        url: DEBTORS_LIST,
        params: { month, teacher, page: pageParam },
    }), {}, 100)
}

export function useDebtorsMonths() {
    return useFetch<GeneralMonthType[]>(['debtorsGeneral'], () => request({ url: DEBTORS_GENERAL }))
}

export function useDebtorUpdate(id: number) {
    return usePutRequest({ url: DEBTORS_DETAIL.replace('{id}', String(id)) })
}

export function useDebtorDiscount(id: number) {
    return usePutRequest({ url: DEBTORS_DISCOUNT.replace('{id}', String(id)) })
}

export function useDebtorDeactivationRequest(id: number) {
    return usePutRequest({ url: DEBTORS_DEACTIVATION.replace('{id}', String(id)) })
}

export function useDebtorDeactivation(id: number) {
    return useDeleteRequest({ url: DEBTORS_DETAIL.replace('{id}', String(id)) })
}

export function useDelayCreate(debtorId: number) {
    const url = DEBTOR_DELAYS_LIST.replace('{id}', String(debtorId))
    return usePostRequest({ url })
}

export function useDelays(debtorId: number) {
    const url = DEBTOR_DELAYS_LIST.replace('{id}', String(debtorId))
    return useLoad<Pagination<DebtorDelayType>>({ url }, [])
}
