import { useCourses } from '../hooks/course'
import Dropdown, { DropdownItem } from '../../core/components/Dropdown'
import Button from '../../core/components/Button'
import { CourseType } from '../types'
import { stringify } from 'query-string'
import { useQueryParams } from '../../core/hooks/queryString'
import { useNavigate } from 'react-router-dom'

export type Props = {}

export default function CustomerActionsCourses({}: Props) {
    const navigate = useNavigate()
    const params = useQueryParams()
    const courses = useCourses({ withCustomers: true }, true)

    function loadCourses() {
        if (courses.data) return
        courses.refetch()
    }

    function filter(course?: CourseType) {
        navigate(`?${stringify({ ...params, course: course?.id })}`)
    }

    return (
        <Dropdown trigger={
            <Button
                text="Курс"
                loading={courses.isLoading}
                onClick={loadCourses}
                icon="ion-md-funnel"
                className="is-small is-outlined" />
        }>

            <DropdownItem
                onClick={() => filter()}
                text={(
                    <div className="columns">
                        <div className="column is-9">
                            Все курсы
                        </div>

                        <div className="column is-3">
                            <span className="tag is-rounded is-pulled-right">
                                {courses.data?.results.reduce((a, c) => c.customersCount + a, 0)}
                            </span>
                        </div>
                    </div>
                )} />

            {courses.data?.results.map((course) => (
                <DropdownItem
                    key={course.id}
                    onClick={() => filter(course)}
                    active={params?.course === String(course.id)}
                    text={(
                        <div className="columns">
                            <div className="column is-9">
                                {course.name}
                            </div>

                            <div className="column is-3">
                                <span className="tag is-rounded is-pulled-right">
                                    {course.customersCount}
                                </span>
                            </div>
                        </div>
                    )} />
            ))}
        </Dropdown>
    )
}
