import DebtorsMonthItem from './DebtorsMonthItem'
import { useDebtorsMonths } from '../hooks/debtor'
import DebtorMothsSkeleton from './DebtorMothsSkeleton'
import { StyleSheet, css } from 'aphrodite'
import { cn } from '../../core/utils/classname'

export type Props = {
    month: string
}

export default function DebtorsMonths({ month }: Props) {
    const general = useDebtorsMonths()

    if (general.isLoading) {
        return <DebtorMothsSkeleton/>
    }

    return (
        <div className={cn('columns', css(styles.scrollable))}>
            {general?.data?.map((item) => (
                <div className="column is-3" key={item.month}>
                    <DebtorsMonthItem item={item} active={item.month === month}/>
                </div>
            ))}
        </div>
    )
}
const styles = StyleSheet.create({
    scrollable: {
        overflowX: 'scroll',
        padding: '0 16px',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})

