import { useEffect } from 'react'
import { useMessage } from '../../core/hooks/message'
import Loader from '../../core/components/Loader'
import Button from '../../core/components/Button'
import { useEmployeeTelegramInvite } from '../hooks/employee'

export type Props = {
    itemId: number | string
    onCancel: () => void
}

export default function EmployeeTelegramInvite({ itemId, onCancel }: Props) {
    const telegramInvite = useEmployeeTelegramInvite(itemId as number)
    const [showMessage] = useMessage()

    useEffect(() => {
        invite()
        // eslint-disable-next-line
    }, [])

    async function invite() {
        const { error } = await telegramInvite.mutate({ data: { userType: 'employee' } })
        if (error) {
            showMessage(error.data.detail, 'is-danger')
        }
    }

    if (telegramInvite.isLoading) {
        return <Loader large center />
    }

    return (
        <div>
            {telegramInvite.data ? (
                <div>
                    <b>Ссылка для приглашения {telegramInvite.data.employee.name}: </b>

                    <input
                        className="input is-disabled"
                        value={`https://t.me/MondayAcademyRobot?start=${telegramInvite.data.code}`}
                        onFocus={({ target }) => target.select()}
                        readOnly />

                    <br />
                    <br />
                </div>
            ) : null}

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыт"
                className="is-danger" />
        </div>
    )
}
