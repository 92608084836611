import isEmpty from 'lodash/isEmpty'
import { cn } from '../../core/utils/classname'
import { css, StyleSheet } from 'aphrodite'
import CustomerBoard from '../components/CustomerBoard'
import AcademyTabs from '../components/AcademyTabs'
import CustomersFilter from '../components/CustomersFilter'
import CustomersActionsBar, { ViewType } from '../components/CustomersActionsBar'
import Layout from '../../core/components/Layout'
import { useQueryParams } from '../../core/hooks/queryString'
import { usePersistState } from '../../core/hooks/state'
import CustomerTable from '../components/CustomerTable'
import { useInfiniteScroll } from '../../core/hooks/request.legacy'
import { CUSTOMER_LIST } from '../urls'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { checkPermission as cp } from '../../users/utils/auth'
import { CustomerType } from '../types'
import { useStatuses } from '../hooks/status'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { stringify } from 'query-string'

export default function Customers() {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const userPositions = user.employee.positions ?? []
    const { type = 'active', search, course, seller } = useQueryParams()
    const [sellerWithDefault, setSellerWithDefault] = useState<number | null>(null)
    const [view, setView] = usePersistState<ViewType>('customersView', 'board') // or table
    const location = useLocation()
    const params = useQueryParams()
    const status = useStatuses()
    const customers = useInfiniteScroll<CustomerType, HTMLDivElement>({
        url: CUSTOMER_LIST,
        params: { type, search, course, seller: seller || sellerWithDefault },
    }, [type, search, course, seller, sellerWithDefault])

    useEffect(() => {
        if (!userPositions.includes('seller')) return
        setSellerWithDefault(seller || user.employee.id)
        const url = `${location.pathname}?${stringify({ ...params, seller: user.employee?.id })}`
        window.history.replaceState(null, '', url)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onUpdate(customerId: number, data: CustomerType) {
        const results = isEmpty(data)
            ? customers.data?.results.filter((item) => item.id !== customerId)
            : customers.data?.results.map((item) => (item.id === customerId ? { ...item, ...data } : item))

        customers.setData({ count: customers.data?.count || 0, results: results || [] })
    }

    if (!cp('academy.view_customer')) {
        return <PermissionDenied />
    }

    // @ts-ignore
    return (
        <Layout className={cn({ 'has-background-light': view !== 'table' })}>
            <AcademyTabs />

            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <CustomersFilter className={css(styles.search)} />
                </div>
            </div>

            <CustomersActionsBar setSellerWithDefault={setSellerWithDefault} view={view} onViewChange={setView} />

            {view === 'table' ? (
                <CustomerTable
                    type={type as CustomerType['type']}
                    onUpdate={onUpdate}
                    statuses={status.data?.results || []}
                    customers={customers} />
            ) : (
                <CustomerBoard
                    type={type as CustomerType['type']}
                    onUpdate={onUpdate}
                    columns={status.data?.results || []}
                    customers={customers}
                    onReloadStatues={status.refetch} />
            )}
        </Layout>
    )
}

const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
})
