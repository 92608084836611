import { cn } from '../../core/utils/classname'
import { stringify } from 'query-string'
import { useNavigate } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { useQueryParams } from '../../core/hooks/queryString'
import { CustomerType } from '../types'
import CustomerActionsCourses from './CustomerActionsCourses'
import CustomerActionsSellers from './CustomerActionsSellers'

export type ViewType = 'table' | 'board'

export type Props = {
    view: ViewType
    onViewChange: (view: ViewType) => void
    setSellerWithDefault: (seller: number | null) => void
}

export default function CustomersActionsBar({ view, onViewChange, setSellerWithDefault }: Props) {
    const navigate = useNavigate()
    const params = useQueryParams()

    function filterByType(type: CustomerType['type'] | '') {
        navigate(`?${stringify({ ...params, type })}`)
    }

    return (
        <div className="columns">
            <div className="column is-narrow">
                <CustomerActionsCourses/>
            </div>
            <div className="column is-narrow">
                <CustomerActionsSellers setSellerWithDefault={setSellerWithDefault}/>
            </div>


            <div className="column">
                <div className="field has-addons are-small">
                    <p className="control">
                        <button className="button is-small"
                            onClick={() => onViewChange('table')}
                            disabled={view === 'table'}
                            title="Отобразить в виде таблицы">

                            <i className={cn('icon ion-md-grid', css(styles.icon))} />
                        </button>
                    </p>

                    <p className="control">
                        <button className="button is-small"
                            onClick={() => onViewChange('board')}
                            disabled={view === 'board'}
                            title="Отобразить в виде колонок">

                            <i className={cn('icon ion-md-list', css(styles.icon))} />
                        </button>
                    </p>
                </div>
            </div>

            <div className="column is-narrow">
                <div className="tags is-right">
                    <a onClick={() => filterByType('')}
                        className={cn('tag', { 'light-primary': params.type === '' })}>

                        <i className="icon ion-ios-people" />
                        Все клиенты
                    </a>

                    <a onClick={() => filterByType('active')}
                        className={cn('tag', { 'light-info': params.type === undefined || params.type === 'active' })}>

                        <i className="icon ion-ios-flame" />
                        Активные
                    </a>

                    <a onClick={() => filterByType('rejected')}
                        className={cn('tag', { 'light-danger': params.type === 'rejected' })}>

                        <i className="icon ion-ios-warning" />
                        Отказавшиеся
                    </a>

                    <a onClick={() => filterByType('archived')}
                        className={cn('tag', { 'light-gray': params.type === 'archived' })}>

                        <i className="icon ion-ios-filing" />
                        Архив
                    </a>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    icon: {
        transform: 'rotate(90deg) scale(1.5)',
    },
})
