import { Fragment, useEffect } from 'react'
import Layout from '../../core/components/Layout'
import Loader from '../../core/components/Loader'
import { useParams, useQueryParams } from '../../core/hooks/queryString'
import { useModal } from '../../core/hooks/modal'
import LessonCreate from '../components/LessonCreate'
import GroupTabs from '../components/GroupTabs'
import Button from '../../core/components/Button'
import { checkPermission as cp } from '../../users/utils/auth'
import Table from '../../core/components/Table'
import LessonItem from '../components/LessonItem'
import { PermissionDenied } from '../../core/components/PermissionDenied'
import { useGroup } from '../hooks/group'
import { useStudents } from '../hooks/student'
import { useLessons } from '../hooks/lesson'

export default function LessonsList() {
    const { groupId } = useParams()
    const params = useQueryParams()
    const group = useGroup(groupId!)
    const students = useStudents({ group: groupId })
    const lessons = useLessons({ group: groupId, page: params.page as string })

    const [showLessonCreateModal, hideLessonCreateModal] = useModal(group.data ? (
        <LessonCreate
            students={students.data?.results || []}
            group={group.data}
            onSuccess={() => {
                lessons.refetch()
                hideLessonCreateModal()
            }} />
    ) : <Fragment />)

    useEffect(() => {
        if (params.create !== undefined && !group.isLoading && !students.isLoading) {
            showLessonCreateModal()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group.isLoading, students.isLoading])

    if (!cp('academy.view_lesson')) {
        return <PermissionDenied />
    }

    if (students.isLoading || group.isLoading || !group.data) {
        return <Layout><Loader large center /></Layout>
    }

    return (
        <Layout>
            <Fragment>
                <GroupTabs group={group.data} />

                {cp('academy.add_lesson') ? (
                    <Button
                        icon="ion-md-add"
                        text="Создать"
                        className="is-link is-outlined is-pulled-right"
                        style={{ marginTop: 20 }}
                        onClick={showLessonCreateModal} />
                ) : null}

                <div className="is-clearfix" /><br />

                <Table
                    responsive
                    columns={{ index: '№', topic: 'Тема', date: 'Дата', actions: '' }}
                    loading={lessons.isLoading}
                    items={lessons.data?.results}
                    totalCount={lessons.data?.count || 0}
                    pageSize={100}
                    renderItem={(item, index) => (
                        <LessonItem
                            item={item}
                            key={item.id}
                            index={index}
                            group={group.data}
                            students={students.data ? students.data.results : []}
                            onDelete={lessons.refetch} />
                    )} />

            </Fragment>
        </Layout>
    )
}
