import find from 'lodash/find'
import { cn } from '../../core/utils/classname'
import { GroupType } from '../types'
import { css, StyleSheet } from 'aphrodite'

export type Props = {
    group: GroupType
    day: string
}

export function TimelineItemLesson({ group, day }: Props) {
    const lesson = find(group.timelines, { day })

    return (
        <span title={group.room.name} className={cn('tag', group.room.color)}>
            <a
                href={`group/${group.id}/students-list`}
                className={cn('has-text-light p-1', css(styles.href))}
                style={styles.href}>
                <b>{group.name}</b> - {lesson?.startTime.slice(0, 5)}
            </a>
        </span>
    )
}

const styles = StyleSheet.create({
    href: {
        textDecoration: 'None',
    },
})
