import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../core/components/ProtectedRoute'
import Customers from './pages/Customers'
import StudentsList from './pages/StudentsList'
import LessonsList from './pages/LessonsList'
import Group from './pages/Group'
import Birthdays from './pages/Birthdays'
import Debtors from './pages/Debtors'
import Timeline from './pages/Timeline'
import Cashback from './pages/Cashback'
import NotFound from '../core/pages/NotFound'

export default function AcademyRoutes() {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="customers" element={<Customers/>} />
                <Route path="group/:groupId/students-list" element={<StudentsList/>}/>
                <Route path="group/:groupId/lessons" element={<LessonsList/>} />
                <Route path="group" element={<Group/>} />
                <Route path="cashback" element={<Cashback/>} />
                <Route path="birthdays" element={<Birthdays/>} />
                <Route path="debtors" element={<Debtors/>} />
                <Route path="timeline" element={<Timeline/>} />
                <Route path="*" element={<NotFound/>} />
            </Route>
        </Routes>
    )
}
